$(document).ready(function() {
	$("[data-fancybox]").fancybox({gall
		maxWidth: 1500,
		slideShow: true,
		loop : true,
		toolbar  : true,
		smallBtn : true,
		transitionEffect : "slide",
		transitionDuration : 366,
		hash : false
	});
	$.fancybox.defaults.hash = false;
});